// ASSETS
const assets = {
  plane: require("../assets/models/plane.glb"),
  star: require("../assets/models/star.glb"),
  bomb: require("../assets/models/bomb.glb"),
  sky: [
    require("../assets/sky/px.jpg"),
    require("../assets/sky/nx.jpg"),
    require("../assets/sky/py.jpg"),
    require("../assets/sky/ny.jpg"),
    require("../assets/sky/pz.jpg"),
    require("../assets/sky/nz.jpg"),
  ],
  sounds: {
    bonus: require("../assets/sounds/bonus.mp3"),
    engine: require("../assets/sounds/engine.mp3"),
    explosion: require("../assets/sounds/explosion.mp3"),
    gameOver: require("../assets/sounds/gameOver.mp3"),
    star: require("../assets/sounds/star.mp3"),
  },
};
// EXPORTS
export { assets };