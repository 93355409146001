// IMPORTS
import "./styles/App.scss";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import Game from "./pages/Game";
// COMPONENT
const App = () => {
  // STATE
  const [state, setState] = useState({
    started: false,
    over: false,
    running: false,
    paused: false,
    health: 3,
    points: 0,
    time: 0,
  });
  const { started, over, running, paused, health, points, time } = state;
  // RENDER
  return (
    <div className="appContainer">
      <Game setState={setState} />
      {running && (
        <div className="menuContainer">
          <div className="menu">
            <p className="menuTitle">Health: {health}</p>
            <p className="menuTitle">Points: {points}</p>
            <p className="menuText">Press P to {paused ? "Play" : "Pause"}</p>
          </div>
        </div>
      )}
      {!started && (
        <div className="menuContainer">
          <p className="menuHeader">Spitfire</p>
          <p className="menuText">- Press the Spacebar to climb</p>
          <p className="menuText">- Collect the stars and avoid the missles</p>
          <p className="menuText">- The game ends when your health reaches 0</p>
          <p className="menuTitle">Press S to start</p>
        </div>
      )}
      {over && (
        <div className="menuContainer">
          <p className="menuHeader">Game Over</p>
          <p className="menuText">- Time: {time} seconds</p>
          <p className="menuText">- Score: {points} points</p>
          <p className="menuTitle">Press R to restart</p>
        </div>
      )}
      {running && paused && (
        <div className="menuContainer">
          <p className="menuHeader">Paused</p>
        </div>
      )}
    </div>
  );
};
// INITIALIZE
ReactDOM.render(<App />, document.getElementById("root"));
