// IMPORTS
import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { randomBetween } from "../helpers/methods";
import { assets } from "../helpers/variables";
// COMPONENT
const Game = ({ setState }) => {
  // STATE
  let mount = useRef(null);
  // LIFE CYCLE
  useEffect(() => {
    // VARIABLES
    const height = window.innerHeight;
    const width = window.innerWidth;
    let started = false;
    let over = false;
    let running = false;
    let paused = false;
    let health = 3;
    let points = 0;
    let time = new THREE.Clock(false);
    let objects = [];
    let stars = [];
    let collisions = [];
    let plane = new THREE.Object3D();
    let propeller = new THREE.Object3D();
    let climbing = false;
    const climbSpeed = 0.005;
    const planeVelocity = new THREE.Vector3(0, 0, 0.1);
    // UPDATE STATE
    const updateState = (newState) => {
      setState((currentState) => {
        return { ...currentState, ...newState };
      });
    };
    // SOUNDS
    const listener = new THREE.AudioListener();
    const sounds = {
      bonus: new THREE.Audio(listener),
      engine: new THREE.Audio(listener),
      explosion: new THREE.Audio(listener),
      gameOver: new THREE.Audio(listener),
      star: new THREE.Audio(listener),
    };
    const loadSounds = () => {
      Object.keys(sounds).forEach((sound) => {
        new THREE.AudioLoader().load(assets.sounds[sound], (buffer) => {
          sounds[sound].setBuffer(buffer);
        });
      });
    };
    const playSound = (sound) => {
      sounds[sound].isPlaying && sounds[sound].stop();
      sounds[sound].play();
    };
    const stopSound = (sound) => {
      sounds[sound].isPlaying && sounds[sound].stop();
    };
    // LIGHTING
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    // CAMERA
    const camera = new THREE.PerspectiveCamera(70, width / height, 0.01, 100);
    camera.position.set(-4.37, 0, -4.75);
    camera.lookAt(0, 0, 6);
    camera.add(listener);
    // CAMERA CONTROLLER
    const cameraController = new THREE.Object3D();
    cameraController.add(camera);
    const cameraTarget = new THREE.Vector3(0, 0, 6);
    // SCENE
    const scene = new THREE.Scene();
    scene.background = new THREE.CubeTextureLoader().load(assets.sky);
    scene.add(ambientLight);
    scene.add(cameraController);
    // RENDERER
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);
    renderer.outputEncoding = THREE.sRGBEncoding;
    mount.current.appendChild(renderer.domElement);
    // MOVE CAMERA
    const moveCamera = () => {
      cameraController.position.copy(plane.position);
      cameraController.position.y = 0;
      cameraTarget.copy(plane.position);
      cameraTarget.z += 6;
      camera.lookAt(cameraTarget);
    };
    // ADD OBJECT
    const addObject = (z) => {
      collisions && (collisions = []);
      let object = new THREE.Group();
      const groupSize = 5;
      const objectYOffset = 2.5;
      const starPosition = randomBetween(1, groupSize);
      for (let i = 1; i <= groupSize; i++) {
        if (i === starPosition) {
          let star = new THREE.Object3D();
          new GLTFLoader().load(assets.star, (gltf) => {
            star = gltf.scene.children[0];
            star.position.y = i * objectYOffset;
            star.name = "star";
            object.add(star);
            stars.push(star);
          });
        } else {
          let bomb = new THREE.Object3D();
          new GLTFLoader().load(assets.bomb, (gltf) => {
            bomb = gltf.scene.children[0];
            bomb.name = "bomb";
            bomb.position.y = i * objectYOffset;
            bomb.rotation.x = (3 * Math.PI) / 2;
            object.add(bomb);
          });
        }
      }
      object.position.x = plane.position.x;
      object.position.y = -10;
      object.position.z = z + 20;
      scene.add(object);
      objects.push(object);
    };
    // COLLISION DETECTION
    const collisionDetection = () => {
      objects.forEach((object) => {
        const z = object.position.z - plane.position.z;
        if (Math.abs(z) < 3) {
          const tempPosition = new THREE.Vector3();
          object.children.forEach((child) => {
            child.getWorldPosition(tempPosition);
            const y = tempPosition.distanceToSquared(plane.position);
            if (y < 3) {
              if (!collisions.includes(child.name)) {
                collisions.push(child.name);
                if (child.name === "star") {
                  playSound("star");
                  points += 1;
                  updateState({ points });
                } else if (child.name === "bomb") {
                  playSound("explosion");
                  health -= 1;
                  updateState({ health });
                  health <= 0 && gameOver();
                }
                object.remove(child);
              }
            }
          });
        }
        if (z < 10) {
          objects.length < 2 && addObject(object.position.z);
        }
        if (z < -5) {
          scene.remove(object);
          objects.shift();
          collisions = [];
        }
      });
    };
    // START GAME
    const startGame = () => {
      started = true;
      running = true;
      updateState({ started, running });
      time.start();
      new GLTFLoader().load(assets.plane, (gltf) => {
        plane = gltf.scene;
        propeller = plane.getObjectByName("propeller");
        scene.add(plane);
      });
      playSound("engine");
      addObject(plane.position.z);
    };
    // RESET GAME
    const resetGame = () => {
      objects.forEach((object) => scene.remove(object));
      over = false;
      running = true;
      paused = false;
      health = 3;
      points = 0;
      time = new THREE.Clock(false);
      updateState({ over, running, paused, health, points, time: 0 });
      plane.position.set(0, 0, 0);
      plane.visible = true;
      planeVelocity.set(0, 0, 0.1);
      Object.keys(sounds).forEach((sound) => {
        sounds[sound].isPlaying && stopSound(sound);
      });
      playSound("engine");
      addObject(plane.position.z);
    };
    // GAME OVER
    const gameOver = () => {
      over = true;
      running = false;
      updateState({
        over,
        running,
        time: Math.floor(time.getElapsedTime()),
      });
      plane.visible = false;
      playSound("gameOver");
    };
    // HANDLE EVENTS
    const handleEvents = () => {
      window.addEventListener("keydown", (event) => {
        event.key === " " && running && (climbing = plane.position.y < 5);
        (event.key === "s" || event.key === "S") && !started && startGame();
        (event.key === "r" || event.key === "R") && over && resetGame();
        if (event.key === "p" || event.key === "P") {
          if (running) {
            if (paused) {
              paused = false;
              updateState({ paused });
              playSound("engine");
              renderer.setAnimationLoop(loop);
            } else {
              paused = true;
              updateState({ paused });
              stopSound("engine");
              renderer.setAnimationLoop(null);
            }
          }
        }
      });
      window.addEventListener("keyup", (event) => {
        event.key === " " && running && (climbing = false);
      });
      window.addEventListener("resize", () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      });
    };
    // LOOP
    const loop = () => {
      if (running) {
        propeller.rotateZ(1);
        stars.forEach((star) => star.rotateY(0.05));
        plane.position.y > 5
          ? stopSound("engine")
          : !sounds.engine.isPlaying && playSound("engine");
        if (plane.position.y < -100) {
          playSound("explosion");
          gameOver();
        }
        climbing
          ? (planeVelocity.y += climbSpeed)
          : (planeVelocity.y -= climbSpeed);
        planeVelocity.z += 0.0001;
        plane.rotation.set(0, 0, Math.sin(time.getElapsedTime() * 3) * 0.2);
        plane.translateZ(planeVelocity.z);
        plane.translateY(planeVelocity.y);
        moveCamera();
        collisionDetection();
      }
      renderer.render(scene, camera);
    };
    // INITIALIZE
    loadSounds();
    handleEvents();
    renderer.setAnimationLoop(loop);
  }, [setState]);
  // RENDER
  return <div ref={mount} />;
};
// EXPORT
export default Game;
